import React from 'react';

export default function Logo(): JSX.Element {
  return (
    <svg width="163" height="32" viewBox="0 0 163 32" xmlns="http://www.w3.org/2000/svg">
      <path d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z" fill="black" />
      <path d="M16.3852 4L18.5975 6.21235L10.8543 13.9556L8.64198 11.7432L16.3852 4Z" fill="white" />
      <path d="M25.7877 13.4025L28 15.6148L20.2568 23.358L18.0444 21.1457L25.7877 13.4025Z" fill="white" />
      <path d="M18.8741 7.59506L24.4049 13.1259L17.7679 19.763L16.1086 18.1037L7.81235 26.4L5.6 24.1877L13.8963 15.8914L12.237 14.2321L18.8741 7.59506Z" fill="white" />
      <path d="M63.3 2.7125V6.9125H61.725L54.3375 29.6H47.775L40.3875 6.9125H38.775V2.7125H49.3875V6.9125H46.2375L51.075 23.0375H51.1125L56.2125 6.9125H52.6875V2.7125H63.3Z" fill="black" />
      <path
        d="M69.75 22.25C69.875 23.25 70.3 24.1 71.025 24.8C71.775 25.475 72.6875 25.8125 73.7625 25.8125C74.5125 25.8125 75.2 25.6625 75.825 25.3625C76.475 25.0375 76.975 24.575 77.325 23.975L82.5 24.5375C81.725 26.3875 80.5625 27.775 79.0125 28.7C77.4875 29.6 75.7125 30.05 73.6875 30.05C71.9625 30.05 70.4 29.6625 69 28.8875C67.6 28.0875 66.5 26.9875 65.7 25.5875C64.9 24.1625 64.5 22.575 64.5 20.825C64.5 19.05 64.8875 17.4625 65.6625 16.0625C66.4625 14.6375 67.55 13.5375 68.925 12.7625C70.325 11.9625 71.8875 11.5625 73.6125 11.5625C75.3125 11.5625 76.85 11.9375 78.225 12.6875C79.625 13.4375 80.7375 14.6 81.5625 16.175C82.4125 17.75 82.8375 19.7125 82.8375 22.0625V22.25H69.75ZM73.6125 15.6875C72.5875 15.6875 71.7125 15.9875 70.9875 16.5875C70.2875 17.1625 69.8375 17.9375 69.6375 18.9125H77.5125C77.3875 17.9875 76.9625 17.225 76.2375 16.625C75.5125 16 74.6375 15.6875 73.6125 15.6875Z"
        fill="black"
      />
      <path
        d="M93.675 29.6H84.7875V25.625H86.55V16.025H84.7875V12.0125H91.3875V14.975C91.7875 13.875 92.3875 13.075 93.1875 12.575C94.0125 12.05 94.9375 11.7875 95.9625 11.7875C96.4625 11.7875 96.925 11.8375 97.35 11.9375V16.925H95.2125C93.9375 16.925 93 17.2625 92.4 17.9375C91.825 18.5875 91.5375 19.675 91.5375 21.2V25.625H93.675V29.6Z"
        fill="black"
      />
      <path
        d="M112.425 27.3875C111.975 28.1375 111.25 28.775 110.25 29.3C109.275 29.8 108.175 30.05 106.95 30.05C105.35 30.05 103.9 29.6625 102.6 28.8875C101.3 28.0875 100.275 26.9875 99.525 25.5875C98.8 24.1625 98.4375 22.575 98.4375 20.825C98.4375 19.05 98.8 17.4625 99.525 16.0625C100.275 14.6375 101.288 13.5375 102.563 12.7625C103.863 11.9625 105.313 11.5625 106.913 11.5625C108.188 11.5625 109.325 11.825 110.325 12.35C111.325 12.85 112.05 13.4875 112.5 14.2625C112.5 13.8625 112.488 13.525 112.463 13.25L112.425 10.925V6.725H110.663V2.7125H117.413V25.625H119.175V29.6H112.425V27.3875ZM108.075 25.6625C109.375 25.6625 110.438 25.2 111.263 24.275C112.113 23.325 112.538 22.175 112.538 20.825C112.538 19.425 112.113 18.275 111.263 17.375C110.438 16.475 109.375 16.025 108.075 16.025C106.75 16.025 105.663 16.475 104.813 17.375C103.988 18.275 103.575 19.425 103.575 20.825C103.575 22.175 103.988 23.325 104.813 24.275C105.663 25.2 106.75 25.6625 108.075 25.6625Z"
        fill="black"
      />
      <path
        d="M122.513 5.9375C122.513 5.0375 122.825 4.275 123.45 3.65C124.075 3.025 124.825 2.7125 125.7 2.7125C126.575 2.7125 127.325 3.025 127.95 3.65C128.6 4.275 128.925 5.0375 128.925 5.9375C128.925 6.8375 128.6 7.6 127.95 8.225C127.325 8.825 126.575 9.125 125.7 9.125C124.8 9.125 124.038 8.825 123.413 8.225C122.813 7.6 122.513 6.8375 122.513 5.9375ZM130.575 25.625V29.6H121.275V25.625H123.413V16.025H121.275V12.0125H128.4V25.625H130.575Z"
        fill="black"
      />
      <path
        d="M149.625 24.9875C149 26.5875 147.938 27.8375 146.438 28.7375C144.963 29.6125 143.263 30.05 141.338 30.05C139.563 30.05 137.963 29.6625 136.538 28.8875C135.138 28.0875 134.038 26.9875 133.238 25.5875C132.438 24.1625 132.038 22.575 132.038 20.825C132.038 19.05 132.413 17.4625 133.163 16.0625C133.913 14.6375 134.938 13.5375 136.238 12.7625C137.538 11.9625 139.025 11.5625 140.7 11.5625C141.6 11.5625 142.45 11.7 143.25 11.975C144.05 12.25 144.7 12.675 145.2 13.25V12.0125H149.475V18.6125H145.313C145.113 17.8875 144.65 17.275 143.925 16.775C143.225 16.275 142.375 16.025 141.375 16.025C140.075 16.025 139.025 16.4625 138.225 17.3375C137.45 18.2125 137.063 19.3375 137.063 20.7125C137.063 22.1375 137.463 23.3125 138.263 24.2375C139.088 25.1625 140.15 25.625 141.45 25.625C142.375 25.625 143.213 25.375 143.963 24.875C144.713 24.375 145.25 23.7125 145.575 22.8875L149.625 24.9875Z"
        fill="black"
      />
      <path
        d="M154.013 5.3375H159V12.0125H162.825V16.025H159V24.275C159 24.825 159.1 25.2125 159.3 25.4375C159.5 25.6375 159.85 25.7375 160.35 25.7375C160.7 25.7375 161.338 25.7 162.263 25.625V29.4875C161.838 29.5875 161.313 29.6625 160.688 29.7125C160.063 29.7875 159.525 29.825 159.075 29.825C157.525 29.825 156.288 29.5625 155.363 29.0375C154.463 28.5125 154.013 27.4625 154.013 25.8875V16.025H151.238V12.0125H154.013V5.3375Z"
        fill="black"
      />
    </svg>
  );
}
