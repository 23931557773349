import React from 'react';
import { SignInAndRegisterModalType } from '../components/signInAndRegister/SignInAndRegisterModalType';
import { User } from 'models/User';

/* eslint-disable */
const SignInContext = React.createContext({
  modalType: SignInAndRegisterModalType.SignIn,
  setModalType: (loginModalType: SignInAndRegisterModalType) => {},
  promptLogin: (loginModalType: SignInAndRegisterModalType, redirectURL?: string, resumeStateKey?: string, resumeStateValue?: Record<string, string>) => {},
  user: null,
  setUser: (user: User) => {},
});

export default SignInContext;
