// DO NOT ADD ANY OTHER IMPORTS HERE
import React from 'react';
import Logo from 'components/svg/Logo';
// DO NOT ADD ANY OTHER IMPORTS HERE
// This is included in _app.jsx and it will make the entire site needlessly bigger on first load.

export default function NavigationBar(): JSX.Element {
  return (
    <div className="verdictMobileDisplayNone">
      <nav className="bg-white border-b">
        <div className="w-full bg-white">
          <div className="max-w-7xl m-auto pl-4 pr-4 sm:pr-0 lg:pr-4">
            <div className="w-full flex justify-between overflow-x-clip">
              <div className="w-full flex shrink items-center justify-between">
                <div className="w-full flex flex-col py-2">
                  <div className="font-medium text-sm font-sans text-gray-800 pr-1 sm:pr-4">
                    <div className="hidden lg:flex leading-none whitespace-nowrap">{true ? 'Get in on the MMA action before this weekend!' : 'Get in on the MMA action before these events!'}</div>
                    <div className="flex lg:hidden leading-none whitespace-nowrap">{true ? 'Submit picks before this weekend!' : 'Submit picks before these events!'}</div>
                    <div className="flex flex-nowrap pt-1.5 gap-1.5">
                      <div className="rounded-full border-gray-200 border bg-white hover:bg-gray-100 px-2.5 py-1.5 leading-none font-medium text-gray-800 cursor-pointer text-xs">{'UFC'}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center flex-1">
                <div className="hidden md:flex w-px h-full bg-gray-200"></div>
                <div className="px-2 md:px-6 flex items-center">
                  <div className="hidden md:flex items-center">
                    <div className="text-right px-2">
                      <div className="text-gray-500 text-xs leading-none pb-0.5 whitespace-nowrap">{''}</div>
                      <div className="text-gray-800 text-sm font-bold font-sans whitespace-nowrap leading-none pb-0.5">{''}</div>
                    </div>
                    <div className="h-9 w-9 bg-gray-800 rounded-full my-2.5">
                      <div className="aspect-square h-9 w-9 bg-gray-800 rounded-full" />
                    </div>
                  </div>
                  <div className={`p-0.5 mx-0 sm:mx-2.5 lg:mx-6 bg-gradient-to-r from-red-base to-blue-base cursor-pointer rounded-full`}>
                    <div className="h-8 px-4 sm:px-8 py-2 bg-gray-800 hover:bg-gray-700 text-white rounded-full flex items-center justify-center text-xs whitespace-nowrap">Make Picks</div>
                  </div>
                  <div className="hidden md:flex items-center">
                    <div className="h-9 w-9 bg-gray-800 rounded-full my-2.5">
                      <div className="aspect-square h-9 w-9 bg-gray-800 rounded-full" />
                    </div>
                    <div className="px-2">
                      <div className="text-gray-500 text-xs leading-none pb-0.5 whitespace-nowrap">{''}</div>
                      <div className="text-gray-800 text-sm font-bold font-sans whitespace-nowrap leading-none pb-0.5">{''}</div>
                    </div>
                  </div>
                </div>
                <div className="hidden lg:flex w-px h-full bg-gray-200"></div>
                <div className="hidden lg:flex">
                  <div className="flex items-center text-red hover:text-red-700 px-5 font-medium font-sans gap-2 cursor-pointer">
                    <div className="whitespace-nowrap">View All</div>
                    <div>
                      <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.50098 9L5.50098 5L1.50098 1" stroke="#C53F35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="hidden lg:flex w-px h-full bg-gray-200"></div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 bg-white">
          <div className="flex justify-between h-20 px-4">
            <div className="flex px-2 lg:px-0">
              <div className="flex-shrink-0 flex items-center justify-center my-4 cursor-pointer">
                <Logo />
              </div>
              <div className="hidden sm:ml-6 md:flex sm:justify-end lg:space-x-2 text-lg w-full px-8 mr-8"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
