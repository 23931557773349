import '../public/css/theme.css';
import './social/globalscorecard/score.css';
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { Analytics } from '../components/Analytics';
import { CookiesProvider } from 'react-cookie';
import SignInContext from '../context/SignInContext';
import { SignInAndRegisterModalType as ModalType } from '../components/signInAndRegister/SignInAndRegisterModalType';
import { Provider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'
import dynamic from 'next/dynamic';
import { ToastProvider } from 'context/ToastContext';
import NavigationBarLoading from '../components/NavigationBarLoading';

const DynamicCookieConsent = dynamic(() => import('../components/CookieConsent'), { ssr: false });
const DynamicFooter = dynamic(() => import('../components/Footer'), { ssr: false });
const DynamicNavigationbar = dynamic(() => import('../components/NavigationBar'), { ssr: false, loading: () => <NavigationBarLoading /> });

const rollbarConfig = {
  accessToken: '53ec1d092f484f89bb37738e9fea7179',
  environment: process.env.NEXT_PUBLIC_VD_ENVIRONMENT || 'development',
  codeVersion: process.env.NEXT_PUBLIC_VD_CODE_VERSION || '1',
  ignoredMessages: ['adsbygoogle already'],
};

export default function MyApp({ Component, pageProps }) {
  const { query, isReady, events, pathname } = useRouter();
  const [modalType, setModalType] = useState(ModalType.None);
  const [user, setUser] = useState(null);

  useEffect(() => {
    Analytics.initialize();
  }, []);

  const promptLogin = (loginModalType, redirectURL, resumeStateKey, resumeStateValue) => {
    setModalType(loginModalType);
    if (redirectURL) {
      sessionStorage.setItem('successfulLoginRedirect', redirectURL);
    }
    if (resumeStateKey && resumeStateValue) {
      sessionStorage.setItem(resumeStateKey, resumeStateValue);
    }
  };

  useEffect(() => {
    const handleRouteChange = (url) => {
      Analytics.track('view_page', { name: url });
    };
    // When the component is mounted, subscribe to router changes
    // and log those page views
    events.on('routeChangeComplete', handleRouteChange);
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);

  useEffect(() => {
    if (!isReady) {
      return;
    }
    Analytics.identify();
    Analytics.track('view_page', { name: pathname });
  }, [isReady]);

  // let noNavigationBarPages = [
  //   'admin',
  // ];
  // Remove soon, mobile clients will handle this.
  let shouldRenderNavigationBar =
    (pathname || '').indexOf('admin') === -1 &&
    (pathname || '').indexOf('readonly') === -1 &&
    (pathname || '').indexOf('safeplay') === -1 &&
    (pathname || '').indexOf('tournaments/payment') === -1 &&
    (pathname || '').indexOf('tournaments/terms') === -1 &&
    (pathname || '').indexOf('tournaments/activity') === -1 &&
    (pathname || '').indexOf('tournaments/legend') === -1 &&
    isReady;
  const isFightForum = (pathname || '').indexOf('fight-forum') !== -1 && (pathname || '').indexOf('fight-forum/create') === -1;
  const isFightPromotion = (pathname || '').indexOf('fight-promotion') !== -1;
  let shouldRenderFooter = !isFightForum && !isFightPromotion && shouldRenderNavigationBar;
  // We don't want to render on the walkthrough pages, since this is the first screen seen on mobile while unauthenticated.
  const shouldRenderCookieConsent = (pathname || '').indexOf('walkthrough/what-is') === -1;
  if (query.isMobile) {
    shouldRenderNavigationBar = false;
    shouldRenderFooter = false;
  } else if (!isReady) {
    // As router is preparing, render nav and footer.
    shouldRenderNavigationBar = true;
    shouldRenderFooter = true;
  }
  const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(false);
  useEffect(() => {
    const isDarkModeEnabled = window.isDarkModeEnabled;
    setIsDarkModeEnabled(isDarkModeEnabled);
  }, []);
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <CookiesProvider>
          <div className={`bg-white ${isDarkModeEnabled ? 'dark' : ''}`}>
            <Head>
              <meta charSet="utf-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
              <meta name="apple-itunes-app" content="app-id=1080144151" />
              <meta property="og:url" content={`${process.env.NEXT_PUBLIC_VD_URL}${pathname}`} />
              <link rel="icon" type="image/png" href="/img/favicon.png" />
              <link rel="manifest" href="/manifest.json" />
              <link rel="preconnect" href="https://adservice.google.com" />
              <link rel="preconnect" href="https://partner.googleadservices.com" />
              <link rel="preconnect" href="https://www.google-analytics.com" />
              <link rel="preconnect" href="https://googleads.g.doubleclick.net" />
            </Head>
            <Script strategy="afterInteractive" src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3814082180872663" crossorigin="anonymous" />
            {shouldRenderCookieConsent && <DynamicCookieConsent />}
            <ToastProvider>
              <SignInContext.Provider value={{ modalType, setModalType, promptLogin, user, setUser }}>
                <>
                  {
                    shouldRenderNavigationBar ? (
                      <DynamicNavigationbar event={pageProps.event} />
                    ) : (
                      <div className="hidden">
                        <DynamicNavigationbar event={pageProps.event} />
                      </div>
                    ) /* Be able to show Login modal when isMobile=1*/
                  }
                  <Component {...pageProps} />
                  {shouldRenderFooter && <DynamicFooter />}
                </>
              </SignInContext.Provider>
            </ToastProvider>
          </div>
        </CookiesProvider>
      </ErrorBoundary>
    </Provider>
  );
}
