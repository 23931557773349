import TagManager from 'react-gtm-module';

let env_check = process.env.NODE_ENV === 'production';

const pageview = (url) => {
  window && window.gtag && window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, { page_path: url });
};

const event = ({ action, params }) => {
  window && window.gtag && window.gtag('event', action, params);
};

const getMixpanel = async () => {
  const MP = (await import('mixpanel-browser')).default;
  MP.init('3fb1f756954333fdb75fbd805d908742');
  return MP;
};

const tagManagerArgs = {
  gtmId: 'GTM-P3BWW75',
};

let actions = {
  initialize: () => {
    TagManager.initialize(tagManagerArgs);
  },
  identify: (id) => {
    if (env_check) {
      getMixpanel().then((mp) => {
        mp.identify(id);
      });
    }
  },
  alias: (id) => {
    if (env_check) {
      getMixpanel().then((mp) => {
        mp.alias(id);
      });
    }
  },
  track: (name, props) => {
    if (env_check) {
      getMixpanel().then((mp) => {
        // There are a lot of these events, filter out for now.
        if (name === 'view_lobby_item' && !props['tournamentID']) {
          return;
        }
        mp.track(name, props);
      });
      if (name === 'view_page') {
        pageview(props.name);
      } else {
        event({ action: name, params: props });
      }
      // Send conversion events to Google Tag Manager.
      if (name.indexOf('success') > -1 || name.indexOf('submit') > -1) {
        TagManager.initialize({ ...tagManagerArgs, dataLayer: { event: name, ...props } });
      }
    }
  },
  people: {
    set: (props) => {
      if (env_check) {
        getMixpanel().then((mp) => {
          mp.people.set(props);
        });
      }
    },
  },
};

export let Analytics = actions;
