import { Transition } from '@headlessui/react';
import React, { Fragment, createContext, useContext, useState } from 'react';

type Toast = {
  content: React.ReactNode;
  className?: string;
};

type ToastContextType = {
  addToast: (toast: Toast) => void;
};

const ToastContext = createContext<ToastContextType>({
  addToast: (_: Toast) => {},
});

export function useToast() {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toast, setToast] = useState<Toast>(null);

  const addToast = (toast: Toast) => {
    if (toast) {
      setToast(toast);
      setTimeout(() => {
        removeToast();
      }, 3000);
    }
  };

  const removeToast = () => {
    setToast(null);
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <div className="fixed z-50 inset-x-0 top-4 left-4 right-4 flex justify-center">
        <Transition.Root show={Boolean(toast) || false} as={Fragment} enter="transition-opacity duration-75" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-150" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className={`max-w-6xl w-full ${toast && toast.className ? toast.className : 'py-2 px-4 bg-gray-50 rounded border border-gray-200'}`}>{toast ? toast.content : <></>}</div>
        </Transition.Root>
      </div>
    </ToastContext.Provider>
  );
}
